import {
    VIDEO_INITIATE_CREATION,
    VIDEO_GET_DETAILS,
    VIDEO_GET_ALL,
    VIDEO_RESUME_CDN_UPLOAD
} from "../actions/videos";

import {
    API_BASE_URL,
    VIDEO_INITIATE_CREATION_URL,
    VIDEO_GET_DETAILS_URL,
    VIDEO_GET_ALL_URL,
    VIDEO_RESUME_CDN_UPLOAD_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [VIDEO_RESUME_CDN_UPLOAD]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VIDEO_RESUME_CDN_UPLOAD_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VIDEO_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VIDEO_GET_ALL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VIDEO_GET_DETAILS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VIDEO_GET_DETAILS_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VIDEO_INITIATE_CREATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VIDEO_INITIATE_CREATION_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};