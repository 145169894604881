import {
    ACCOUNT_LOGIN,
} from "../actions/user";

import {
    API_BASE_URL,
    ACCOUNT_LOGIN_URL,
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [ACCOUNT_LOGIN]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_LOGIN_URL,
            method: "POST",
            data: payload,
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};