<script>
import moment from 'moment';
export default {
  methods: {
    moment: moment,
    $getLoggedUser() {
      const data = JSON.parse(localStorage.getItem('logged-user'));
      return data.user;
    }
  }
}
</script>