<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <b-modal v-model="activate">
          <radioModal :data="form" :type="type" @cancel="cancel()" />
        </b-modal>
        <div class="top">
          <div class="locator">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
            RADIO
          </div>
          <b-button class="add" @click="activateModal()" type="is-info" size="is-small" icon-left="plus">ADD RADIO</b-button>
        </div>
        <div class="table">
          <div class="headings">
            <div class="heading"><h3>Channel name</h3></div>
            <div class="heading"><h3>Status</h3></div>
            <div class="heading"><h3>Origin</h3></div>
            <div class="heading"><h3>Action</h3></div>
          </div>
          <div class="record" v-for="radio in radios" :key="radio.id">
            <div class="data play">
              <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_201_216)"><path d="M12.0001 11C10.9001 11 10.0001 11.9 10.0001 13C10.0001 14.1 10.9001 15 12.0001 15C13.1001 15 14.0001 14.1 14.0001 13C14.0001 11.9 13.1001 11 12.0001 11ZM18.0001 13C18.0001 9.43999 14.8901 6.59999 11.2501 7.04999C8.63005 7.37 6.47005 9.45999 6.07005 12.07C5.74005 14.22 6.56005 16.18 8.00005 17.47C8.48005 17.9 9.23005 17.8 9.56005 17.24L9.57005 17.23C9.81005 16.81 9.71005 16.3 9.35005 15.97C8.32005 15.04 7.76005 13.6 8.13005 12.03C8.46005 10.61 9.61005 9.45999 11.0301 9.11999C13.6501 8.48999 16.0001 10.47 16.0001 13C16.0001 14.18 15.4801 15.23 14.6701 15.96C14.3101 16.28 14.2001 16.8 14.4401 17.22L14.4501 17.23C14.7601 17.76 15.4801 17.92 15.9501 17.51C17.2001 16.41 18.0001 14.8 18.0001 13ZM10.8301 3.06999C6.21005 3.58999 2.48005 7.39999 2.05005 12.03C1.70005 15.73 3.37005 19.05 6.07005 21.04C6.55005 21.39 7.23005 21.24 7.53005 20.73C7.78005 20.3 7.67005 19.74 7.27005 19.44C4.99005 17.75 3.62005 14.89 4.11005 11.74C4.65005 8.23999 7.57005 5.44999 11.0901 5.05999C15.9101 4.50999 20.0001 8.27999 20.0001 13C20.0001 15.65 18.7101 17.98 16.7301 19.44C16.3301 19.74 16.2201 20.29 16.4701 20.73C16.7701 21.25 17.4501 21.39 17.9301 21.04C20.4001 19.22 22.0001 16.3 22.0001 13C22.0001 7.08999 16.8701 2.37999 10.8301 3.06999Z" fill="#323232"/></g><defs><clipPath id="clip0_201_216"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
              </div>
              <h3>{{ radio.channelName }}</h3>
            </div>
            <div class="data">
              <h3>
                <span v-if="radio.status == false" class="circle false"></span>
                <span v-else class="circle true"></span>
                <label v-if="radio.status">Live</label>
                <label v-else>Closed</label>
              </h3>
            </div>
            <div class="data"><h3>{{ radio.origin }}</h3></div>
            <div class="data action">
                <b-dropdown aria-role="list">
                    <template #trigger="{ active }">
                        <b-button
                          :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item aria-role="listitem" @click="activateModal(radio)">Edit</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="activateModal(radio,'delete')">Delete</b-dropdown-item>
                </b-dropdown>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import radioModal from './components/radioModal.vue';
export default{
  name: "radio",
  components: { radioModal },
  data(){
    return{
      activate: false,
      type: '',
      form:{
        channelName: '',
        origin: '',
      },
      radios: [
        {
          id: 1,
          channelName: 'Radio Rwanda',
          status: true,
          origin: 'watch.rw',
          action: false
        },
        {
          id: 2,
          channelName: 'City radio',
          status: false,
          origin: 'reba.rw',
          action: false
        },
      ]
    }
  },
  methods:{
    addRadio(){
      console.log('new radio...');
    },
    activateModal(data,type){
      let vm = this
      data ? vm.form = data : ''
      type ? vm.type = type : ''
      vm.activate = true
    },
    cancel(){
      this.activate = false
      this.type = ''
      this.form = { channelName: '', origin: ''}
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/index.scss';
.contain{
  padding: 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
    div{
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 10px;
      svg{
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }
      &.add{
        padding: 5px 10px;
        border-radius: 5px;
        background: $lightblue;
        color: $white;
        cursor: pointer;
        svg{
          fill: $white;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .table{
    .headings{
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 0.8fr;
      margin: 0 0 10px;
      column-gap: 20px;
      padding: 0 10px;
      .heading{
        h3{
          font-size: 0.9rem;
          font-weight: 500;
          margin: 5px 0; 
          color: $black;
        }
      }
    } 
    .record{
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 0.8fr;
      margin: 10px 0;
      padding: 7px 10px;
      border-radius: 7px;
      column-gap: 20px;
      transition: .8s;
      &:hover{
        background: rgb(241, 241, 241);
      }
      .data{
        h3{
          font-size: 0.8rem;
          font-weight: 400;
          margin: 5px 0; 
          line-height: 1.7rem;
          color: $black;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: flex;
          align-items: center;
          span{
            width: 10px;
            height: 10px; 
            display: block;
            margin: 0 7px 0 0; 
            background: #0AEE81;
            border-radius: 100%; 
            &.queued{
              background: orange;
            }
            &.true{
              background: #0AEE81;
            }
            &.false{
              background: salmon;
            }
          }
        }
        &.play,&.action{
          display: flex;
          column-gap: 10px;
          align-items: center;
          .icon{
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: relative;
            img{
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
        &.action{
          font-size: 0.8rem;
          position: relative;
          .actions{
            display: flex;
            column-gap: 10px;
            position: absolute;
            right: 100%; 
            opacity: 0;
            transition: .4s;
            pointer-events: none;
            label{
              background: $grey;
              padding: 3px 10px;
              border-radius: 4px;
              cursor: pointer;
            }
            &.active{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    } 
  }
}
</style>