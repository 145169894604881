<template>
  <div class="topbar">
    <b-field>
      <b-field>
        <b-select placeholder="Server" icon="server">
          <option value="Server 1">Server 1</option>
          <option value="Server 2">Server 2</option>
        </b-select>
      </b-field>
    </b-field>
    <div class="profile" @click="toggle()">
      <div class="profile-img">
        <img src="@/assets/imgs/default-profile.jpg" alt="Profile Picture">
      </div>
      <label>{{ $getLoggedUser().firstName }} {{ $getLoggedUser().lastName }}</label>
      <button class="logout" @click="logout()" :class="{ active: show }">
        Log out
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    logout() {
      localStorage.removeItem('logged-user');
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/index.scss';

.field {
  margin: 0 !important;
  font-size: 0.8rem;

  .control {
    font-size: 0.9rem;
  }
}

.topbar {
  padding: 10px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $grey;
  z-index: 9;
  border-bottom: 1px solid #7171711b;
  position: sticky;
  top: 0;

  .profile {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    position: relative;

    .profile-img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    button {
      outline: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 5px 20px;
      cursor: pointer;
      border-radius: 4px;
      font-size: 0.8rem;
      column-gap: 10px;
      background: $darkblue;
      border: none;
      justify-content: center;
      color: #fff;
      white-space: nowrap;
      position: absolute;
      right: 100%;
      opacity: 0;
      transition: .8s;
      pointer-events: none;

      &.active {
        pointer-events: all;
        right: 120%;
        opacity: 1;
      }
    }
  }
}
</style>