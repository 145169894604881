<template>
  <div class="overlay">
    <div class="deletepop" v-if="type == 'delete'">
      <label>Are you sure you want to delete this user?</label>
      <div class="btns">
        <b-button type="is-primary">Delete</b-button>
        <b-button type="is-primary is-light" @click="cancel()">Cancel</b-button>
      </div>
    </div>
    <form class="addpop" v-else-if="form">
      <div class="top">
        <label class="head" v-if="!form.id">Add user</label>
        <label class="head" v-else>Edit user</label>
      </div>
      <div class="flex">
        <div>
          <label>First Name</label>
          <b-input type="text" v-model="form.firstName" placeholder="Enter firstname" required></b-input>
        </div>
        <div>
          <label>Last Name</label>
          <b-input type="text" v-model="form.lastName" placeholder="Enter lastname" required></b-input>
        </div>
      </div>
      <label>Email</label>
      <b-input type="email" v-model="form.email" placeholder="Enter origin" required></b-input>
      <label>Role</label>
      <select v-model="form.role" required>
        <option value="" hidden>Assign role</option>
        <option value="Super Admin">Super Admin</option>
        <option value="Admin">Admin</option>
      </select>
      <label>Password</label>
      <b-input type="password" v-model="form.password" placeholder="Enter password" required></b-input>
      <div class="btns">
        <b-button type="is-primary" v-if="form.id">Update user</b-button>
        <b-button type="is-primary" v-else>Save</b-button>
        <b-button type="is-primary is-light" @click.prevent="cancel()">Cancel</b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "usersModal",
  props: {
    data: { 
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data(){
    return{
      form: null
    }
  },
  methods:{
    currentData(){
      let vm = this
      vm.form = {
        firstName: vm.data.firstName,
        lastName: vm.data.lastName,
        email: vm.data.email,
        role: vm.data.role,
        password: vm.data.password
      }
    },
    save(){
      console.log("saving changes......")
    },
    cancel(){
      let vm = this
      vm.$emit('cancel')
    }
  },
  mounted(){
    this.currentData()
  }
}
</script>

<style>

</style>