<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <b-modal v-model="activate">
          <AppModal :data="form" :type="type" @cancel="cancel()" />
        </b-modal>
        <div class="top">
          <div class="locator">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_201_257)"><path d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99996C9.74996 2 9.53996 2.18 9.50996 2.42L9.12996 5.07C8.51996 5.32 7.95996 5.66 7.43996 6.05L4.94996 5.05C4.71996 4.96 4.45996 5.05 4.33996 5.27L2.33996 8.73C2.20996 8.95 2.26996 9.22 2.45996 9.37L4.56996 11.02C4.52996 11.34 4.49996 11.67 4.49996 12C4.49996 12.33 4.52996 12.66 4.56996 12.98L2.45996 14.63C2.26996 14.78 2.21996 15.05 2.33996 15.27L4.33996 18.73C4.45996 18.95 4.72996 19.03 4.94996 18.95L7.43996 17.95C7.95996 18.35 8.51996 18.68 9.12996 18.93L9.50996 21.58C9.53996 21.82 9.74996 22 9.99996 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.49996 13.93 8.49996 12C8.49996 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z" /></g><defs><clipPath id="clip0_201_257"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
            SETTINGS
          </div>
        </div>
        <section>
          <b-tabs size="is-small" class="block">
              <b-tab-item label="VIDEO SETTINGS">
                <div class="top">
                  <div class="locator">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
                    S3 STORAGE SETTINGS 
                  </div>
                </div>
                <div class="properties">
                  <h2 class="property">PROVIDER: <span>Digital</span></h2>
                  <h2 class="property">ACCESS KEY: <span>wecodefy</span></h2>
                  <h2 class="property">SECRET ACCESS KEY: <span>wecodefy</span></h2>
                  <h2 class="property">BUCKET NAME: <span>wecodefy</span></h2>
                  <h2 class="property">REGION: <span>us-east-1</span></h2>
                  <h2 class="property">ENDPOINT: <span>https://fra1.digitaloceanspaces.com</span></h2>
                </div>
              </b-tab-item>
              <b-tab-item label="STREAM SETTINGS">
                <div class="top">
                  <div class="locator">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
                    ACTIVE STREAM PLATFORM 
                  </div>
                </div>
                <div class="properties">
                  <div class="wrap-property">
                    <h2 class="property">WECODEFY V1 ENCODER </h2>
                    <b-field>
                        <b-switch :value="true" type="is-info" size="is-small"></b-switch>
                    </b-field>  
                  </div>
                  <div class="wrap-property">
                    <h2 class="property">WECODEFY V2 ENCODER</h2>
                    <b-field>
                        <b-switch :value="false" type="is-info" size="is-small"></b-switch>
                    </b-field>
                  </div>
                  <div class="wrap-property">
                    <h2 class="property">CLOUD FLAYER ENCODER</h2>
                    <b-field>
                        <b-switch :value="true" type="is-info" size="is-small"></b-switch>
                    </b-field>
                  </div>
                  <div class="wrap-property">
                    <h2 class="property">OVENMEDIA ENGINE</h2>
                    <b-field>
                        <b-switch :value="false" type="is-info" size="is-small"></b-switch>
                    </b-field>
                  </div>
                  <div class="wrap-property">
                    <h2 class="property">ENCODER</h2>
                    <b-field>
                        <b-switch :value="false" type="is-info" size="is-small"></b-switch>
                    </b-field>
                  </div>
                  <div class="wrap-property">
                    <h2 class="property">ENDPOINT</h2>
                    <b-field>
                        <b-switch :value="true" type="is-info" size="is-small"></b-switch>
                    </b-field>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="APP MANAGEMENT">
                <div class="top">
                  <div class="locator">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
                    SUPPORTED PLATFORM
                  </div>
                  <b-button class="add" @click="activateModal()" type="is-info" size="is-small" icon-left="plus">ADD PLATFORM</b-button>
                </div>
                <div class="table">
                  <div class="record" v-for="(app,index) in apps" :key="index">
                    <div class="index"><h3>{{ index }}</h3></div>
                    <div class="details">
                      <h3 class="detail">STREAM CALLBACK: <span>{{ app.streamCallback }}</span></h3>
                      <h3 class="detail">VIDEO CALLBACK: <span>{{ app.videoCallback }}</span></h3>
                      <h3 class="detail">S3 BACKET FOLDER: <span>{{ app.s3BacketFolder }}</span></h3>
                    </div>
                    <div class="option">
                      <b-button @click="activateModal(app)" type="is-info" size="is-small" icon-left="pencil">EDIT</b-button>
                      <b-button @click="activateModal(app,'delete')" type="is-danger" size="is-small" icon-left="delete">REMOVE</b-button>
                    </div>
                  </div>
                </div>
              </b-tab-item>
          </b-tabs>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import AddUpdateAppModal from './components/AddUpdateAppModal.vue';
export default{
  name: "settings",
  components: { 'AppModal': AddUpdateAppModal },
  data(){
    return{
      activate: false,
      type: '',
      form:{
        platformName: '',
        streamCallback: '',
        videoCallback: '',
        s3BacketFolder: ''
      },
      apps: [
        {
          id: 1,
          platformName: "Watch",
          streamCallback: "https://wa/api/v1/stream/callback",
          videoCallback: "https://wa/api/v1/stream/callback",
          s3BacketFolder: "/watch/",
        },
        {
          id: 2,
          platformName: "Watch",
          streamCallback: "https://wa/api/v1/stream/callback",
          videoCallback: "https://wa/api/v1/stream/callback",
          s3BacketFolder: "/watch/",
        }
      ]
    }
  },
  methods:{
    addSetting(){
      console.log('new stream...');
    },
    activateModal(data,type){
      let vm = this
      data ? vm.form = data : ''
      type ? vm.type = type : ''
      vm.activate = true
    },
    cancel(){
      this.activate = false
      this.type = ''
      this.form = { streamCallback: '', videoCallback: '' , s3BacketFolder: ''}
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/index.scss';
.contain{
  padding: 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
    div{
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 10px;
      svg{
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }
      &.add{
        padding: 5px 10px;
        border-radius: 5px;
        background: $lightblue;
        color: $white;
        cursor: pointer;
        svg{
          fill: $white;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .properties{
    .property{
      margin-bottom: 10px;
      font-size: 0.9rem;
      font-weight: 600;
      span{
        font-weight: 300;
      }
    }
    .wrap-property{
      display: grid;
      grid-template-columns: 40% 10%;
      column-gap: 30px; 
      margin-bottom: 10px;
      align-items: center;
      .property{
        margin: 0;
      }
    }
  }
  .tabs{
    display: flex;
    column-gap: 20px;
    font-size: 0.8rem;
    margin: 20px 0;
    .tab{
      text-transform: uppercase;
      font-weight: 400;
      padding: 7px 0;
      cursor: pointer;
      &.active{
        border-bottom: 1px solid $darkblue;
      }
    }
  }
  .table{
    // .headings{
    //   display: grid;
    //   grid-template-columns: 0.5fr 3fr 1fr;
    //   margin: 0 0 10px;
    //   column-gap: 20px;
    //   padding: 0 10px;
    //   .heading{
    //     h3{
    //       font-size: 0.9rem;
    //       font-weight: 500;
    //       margin: 5px 0; 
    //       color: $black;
    //     }
    //   }
    // } 
    .record{
      display: grid;
      grid-template-columns: 0.5fr 4fr 1fr;
      margin: 10px 0;
      padding: 7px 10px;
      border-radius: 7px;
      column-gap: 20px;
      transition: .8s;
      .index{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 0.8rem;
        border: 2px solid $black;
      }
      .details{
        display: grid;
        grid-template-columns: 1fr 1fr;
        h3{
          font-size: 0.7rem;
          font-weight: 500;
          margin: 5px 0; 
          color: $lightblue;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: flex;
          align-items: center;
          span{
            color: $black;
            font-weight: 400;
            margin: 0 0 0 5px;  
          }
        }
      }
      .option{
        display: flex;
        column-gap: 10px;
      }
    } 
  }
}
</style>