<template>
  <div class="login">
    <div class="form">
      <div class="top">
        <div class="logo">
          <img src="@/assets/logo.svg" alt="">
        </div>
      </div>
      <div class="inputs">
        <label>Email</label>
        <input type="email" v-model="loginForm.email" placeholder="Enter your email" required>
        <label>Password</label>
        <input type="password" v-model="loginForm.password" placeholder="Enter your password" required>
        <b-button type="is-primary" @click="login()" :loading="loading" expanded>Sign in</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loginForm: {
        email: 'admin@streamserver.com',
        password: '12345'
      },
    }
  },
  methods: {
    login() {
      let vm = this;
      vm.loading = true
      vm.$store.dispatch('ACCOUNT_LOGIN', vm.loginForm)
        .then(response => {
          vm.loading = false;
          localStorage.setItem('logged-user', JSON.stringify(response.data));
          vm.$store.commit('SET_TOKEN', response.data.accessToken);
          vm.$buefy.toast.open({
            message: 'Login successful',
            type: 'is-success'
          });
          vm.$router.push('/dashboard');
        })
        .catch(error => {
          console.log(error)
          vm.loading = false
        })
    },
    checklogged() {
      if (localStorage.getItem('logged-user')) {
        this.$router.push('/dashboard')
      } else {
        this.$router.push('/')
      }
    },
  },
  mounted() {
    this.checklogged()
  },
}
</script>

<style lang="scss" scoped>
$darkblue: #0F2F64;
$white: #fff;

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 450px;
  background: $white;
  border-radius: 7px;
  padding: 15px 0;
  transition: .6s;
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 95%;
  }

  .top {
    padding: 5px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;

    .logo {
      width: 130px;
      height: 50px;
      position: relative;

      img {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .inputs {
    overflow: hidden;
    padding: 20px 30px;
    width: 100%;
    transition: .8s;

    .flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }

    label {
      display: block;
      font-size: 0.85rem;
      color: $darkblue;
    }

    input,
    textarea {
      outline: none;
      resize: none;
      display: block;
      margin: 10px 0;
      padding: 7px 10px;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 0.8rem;
    }

    button {
      outline: none;
      margin: 10px 0 15px 0;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      cursor: pointer;
      border-radius: 4px;
      font-size: 0.8rem;
      column-gap: 15px;
      background: $darkblue;
      position: relative;
      overflow: hidden;
      border: none;
      width: 100%;
      justify-content: center;
      color: #fff;
    }
  }
}
</style>