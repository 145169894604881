<template>
  <div class="table">
    <div class="record" v-for="(server,index) in originServers" :key="index">
      <div class="index"><h3>{{ index + 1 }}</h3></div>
      <div class="descript">
        <h3>{{ server.name }}</h3>
        <div class="details">
          <h3 class="detail">IP: <span>{{ server.ip }}</span></h3>
          <h3 class="detail">User: <span>{{ server.user }}</span></h3>
          <div class="wrap-detail">
            <h3 class="detail">Password: <span v-if="server.show">{{ server.password }}</span> <span v-else>*********</span></h3>
            <label @click="server.show = !server.show">show</label>
          </div>
        </div>
      </div>
      <h3 v-if="server.status">INSTALLING</h3> <h3 v-else>_______</h3>
      <div class="option">
        <b-button @click="activateModal(server,'attach')" type="is-success" size="is-small" icon-left="paperclip">ATTACH SERVER</b-button>
        <b-button @click="activateModal(server,'delete')" type="is-danger" size="is-small" icon-left="delete">REMOVE</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'edgesServers',
data(){
  return{
    originServers: [
      {
        id: 1,
        name: "Reba",
        ip: "166.43.422.4",
        user: "root",
        password: "jfiewoew",
        status: true,
        show: false
      },
      {
        id: 2,
        name: "Watch",
        ip: "178.01.422.4",
        user: "root",
        password: "32",
        status: false,
        show: false
      }
    ],
  }
},
methods:{
  activateModal(data,type){
    let vm = this
    vm.$emit('activateModal',data,type);
  }
}
}
</script>

<style lang="scss" scoped>
@import '../../../scss/index.scss';
.table{
  .record{
    display: grid;
    grid-template-columns: 0.5fr 2.5fr 1fr 1fr;
    margin: 10px 0;
    padding: 7px 10px;
    border-radius: 7px;
    column-gap: 20px;
    transition: .8s;
    .index{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 0.8rem;
      border: 2px solid $black;
    }
    .descript{
      h3{
        font-size: 0.8rem;
        font-weight: 600;
        margin: 3px 0; 
        color: $black;
      }
    }
    h3{
      font-size: 0.8rem;
      font-weight: 600;
      margin: 3px 0; 
      color: $black;
    }
    .details{
      display: grid;
      grid-template-columns: 1fr;
      h3{
        font-size: 0.7rem;
        font-weight: 500;
        margin: 2px 0; 
        color: $lightblue;
        span{
          color: $black;
          font-weight: 400;
          margin: 0 0 0 5px;  
        }
      }
      .wrap-detail{
        display: flex;
        align-items: center;
        label{
          margin-left: 30px;
          cursor: pointer;
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
    }
    .option{
      display: flex;
      column-gap: 10px;
    }
  } 
}
</style>