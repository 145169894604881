export const BASE_URL = process.env.NODE_ENV === 'development' ? "http://127.0.0.1:3000" : "https://media-server-api.watch.rw";
export const API_BASE_URL = BASE_URL + '/api/v1';


/* ---------------------------------------------------------------
 * API LIST
 ----------------------------------------------------------------*/
// Auth apis
export const ACCOUNT_LOGIN_URL = "/auth/login";


// Video apis
export const VIDEO_INITIATE_CREATION_URL = "/videos/initiate-creation";
export const VIDEO_GET_DETAILS_URL = "/videos/get-details";
export const VIDEO_GET_ALL_URL = "/videos/all";
export const VIDEO_RESUME_CDN_UPLOAD_URL = "/videos/resume-cdn-upload";