<template>
  <div class="overlay">
    <div class="addpop">
      <div class="top">
        <label class="head">Add a video</label>
      </div>
      <label>Upload</label>
      <b-field>
        <UploadWidget app="watch.rw" accept=".mp4,.mkv,.mp3" :libraryId="libraryId" :videoId="videoId"
          :streamServerBaseUrl="baseUrl" :canCreate="true" @created="(data) => { }" @started="(data) => { }"
          @progress="(data) => { onProgress(data) }" @complete="(data) => { }" @error="(error) => { }">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large">
                </b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </UploadWidget>
      </b-field>
      <div class="btns">
        <b-button type="is-primary is-light" @click.prevent="cancel()">Ok</b-button>
      </div>


    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/store/variables.js"
import UploadWidget from "@/components/UploadWidget.vue"
export default {
  name: "videoModal",
  components: {
    UploadWidget
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      baseUrl: BASE_URL,
      videoId: '8f0cdce2-3e3f-421a-80b4-d2e14585ff1c',
      libraryId: '147551'
    }
  },
  mounted() {
  },
  methods: {
    onProgress(data) {
      console.log(data);
    },
    getVideoDetails() {
      let vm = this;
      vm.$store.dispatch('VIDEO_GET_DETAILS', {
        videoId: vm.videoId,
        libraryId: vm.libraryId
      })
        .then(response => {
          //console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        })
    },
    save() {
      console.log("saving changes......")
    },
    cancel() {
      let vm = this
      vm.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/index.scss';
</style>