<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <b-modal v-model="activate">
          <usersModal :data="form" :type="type" @cancel="cancel()" />
        </b-modal>
        <div class="top">
          <div class="locator">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
            USERS
          </div>
          <b-button class="add" @click="activateModal()" type="is-info" size="is-small" icon-left="plus">ADD USER</b-button>
        </div>
        <div class="table">
          <div class="headings">
            <div class="heading"><h3>Name</h3></div>
            <div class="heading"><h3>Email</h3></div>
            <div class="heading"><h3>Role</h3></div>
            <div class="heading"><h3>Action</h3></div>
          </div>
          <div class="record" v-for="user in users" :key="user.id">
            <div class="data"><h3>{{ user.firstName }} {{ user.lastName }}</h3></div>
            <div class="data"><h3>{{ user.email }}</h3></div>
            <div class="data"><h3>{{ user.role }}</h3></div>
            <div class="data action">
                <b-dropdown aria-role="list">
                    <template #trigger="{ active }">
                        <b-button
                          :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item aria-role="listitem" @click="activateModal(user)">Edit</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="activateModal(user,'delete')">Delete</b-dropdown-item>
                </b-dropdown>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import usersModal from './components/usersModal.vue'
export default{
  name: "users",
  components: { usersModal },
  data(){
    return{
      activate: false,
      type: '',
      form:{
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        password: ''
      },
      users: [
        {
          id: 1,
          firstName: 'Abe',
          lastName: 'Jahwin',
          email: 'ajahwin@gmail.com',
          role: 'Super Admin',
          password: 'ewana',
          action: false
        },
        {
          id: 2,
          firstName: 'Ishimwe',
          lastName: 'Hirwa',
          email: 'ihirwa@gmail.com',
          role: 'Admin',
          password: 'ewana',
          action: false
        },
      ]
    }
  },
  methods:{
    cancel(){
      this.activate = false
      this.type = ''
      this.form = { firstName: '', lastName: '', email: '', role: '', password: ''}
    },
    activateModal(data,type){
      let vm = this
      data ? vm.form = data : ''
      type ? vm.type = type : ''
      vm.activate = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/index.scss';
.contain{
  padding: 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
    div{
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 10px;
      svg{
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }
      &.add{
        padding: 5px 10px;
        border-radius: 5px;
        background: $lightblue;
        color: $white;
        cursor: pointer;
        svg{
          fill: $white;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .table{
    .headings{
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 0.8fr;
      margin: 0 0 10px;
      column-gap: 20px;
      padding: 0 10px;
      .heading{
        h3{
          font-size: 0.9rem;
          font-weight: 500;
          margin: 5px 0; 
          color: $black;
        }
      }
    } 
    .record{
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 0.8fr;
      margin: 10px 0;
      padding: 7px 10px;
      border-radius: 7px;
      column-gap: 20px;
      transition: .8s;
      &:hover{
        background: rgb(241, 241, 241);
      }
      .data{
        h3{
          font-size: 0.8rem;
          font-weight: 400;
          margin: 5px 0; 
          line-height: 1.7rem;
          color: $black;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        &.play,&.action{
          display: flex;
          column-gap: 10px;
          align-items: center;
          .icon{
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: relative;
            img{
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
        &.action{
          font-size: 0.8rem;
          position: relative;
          .actions{
            display: flex;
            column-gap: 10px;
            position: absolute;
            right: 100%; 
            opacity: 0;
            transition: .4s;
            pointer-events: none;
            label{
              background: $grey;
              padding: 3px 10px;
              border-radius: 4px;
              cursor: pointer;
            }
            &.active{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    } 
  }
}
</style>