import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
// components
import Sidebar from "@/components/Sidebar.vue"
import Topbar from "@/components/Topbar.vue"
// mixin
import Mixin from "@/mixins/mixin.vue"
// plug-components
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import OvenPlayer from "@/components/OvenPlayer.vue"


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  try {
    if (error.response.status === 401) {
      store.commit("SET_TOKEN", null);
      localStorage.removeItem('logged-user');
      router.push({ path: "/" });
    }
  } catch (e) { }
  return Promise.reject(error);
});



Vue.component("Sidebar", Sidebar)
Vue.component("Topbar", Topbar)
Vue.component("OvenPlayer", OvenPlayer)

Vue.mixin(Mixin)
Vue.use(store)
Vue.use(Buefy)
Vue.use(router)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')