<template>
  <div class="overlay">
    <div class="deletepop" v-if="type == 'delete'">
      <label>Are you sure you want to remove this server?</label>
      <div class="btns">
        <b-button type="is-primary">Delete</b-button>
        <b-button type="is-primary is-light" @click="cancel()">Cancel</b-button>
      </div>
    </div>
    <form class="addpop" v-else-if="form">
      <div class="top">
        <label class="head" v-if="!form.id">Add server</label>
        <label class="head" v-else>Attach server</label>
      </div>
      <div class="attach" v-if="type === 'attach'">
        <label>Server IP</label>
        <b-input 
          placeholder="Enter server IP"
          icon-right="close-circle"
          icon-right-clickable
          @icon-right-click="removeInput(index)"
          v-for="(ip,index) in ips"
          :key="index"
          v-model="ip.ip"
          >
        ></b-input>
        <b-button type="is-primary is-light" size="is-small" @click="addInput()">ADD NEW</b-button>
      </div>
      <div class="add" v-else>
        <label>Type</label>
        <select v-model="form.type" required>
          <option value="" hidden>Select server type</option>
          <option value="origin">Origin</option>
          <option value="edges">Edges</option>
          <option value="load">Load</option>
        </select>
        <label>Name</label>
        <b-input type="text" v-model="form.name" placeholder="Enter radio title" required></b-input>
        <label>IP</label>
        <b-input type="text" v-model="form.ip" placeholder="Enter ip address" required></b-input>
        <label>User</label>
        <b-input type="text" v-model="form.user" placeholder="Enter user" required></b-input>
        <label>Password</label>
        <b-input type="password" v-model="form.password" placeholder="Enter password" required></b-input>
      </div>
      <div class="btns">
        <b-button type="is-primary">Save changes</b-button>
        <b-button type="is-primary is-light" @click.prevent="cancel()">Cancel</b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "radioModal",
  props: {
    data: { 
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data(){
    return{
      form: null,
      ips: [{ ip: '' }],
    }
  },
  methods:{
    currentData(){
      let vm = this
      vm.form = {
        id: vm.data.id ? vm.data.id : null,
        ip: vm.data.ip,
        user: vm.data.user,
        password: vm.data.password,
        type: vm.data.type
      }
    },
    save(){
      console.log("saving changes......")
    },
    addInput(){
      this.ips.push({ ip: '' })
    },
    removeInput(index){
      this.ips.length > 1 ? this.ips.splice(index,1) : ''
    },
    cancel(){
      let vm = this
      vm.$emit('cancel')
    }
  },
  mounted(){
    this.currentData()
  }
}
</script>

<style>

</style>