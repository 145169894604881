<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <b-modal v-model="activate">
          <videoModal :data="form" :type="type" @cancel="cancel()" />
        </b-modal>
        <b-modal v-model="showPlayer">
          <OvenPlayer :hlsUrl="hlsUrl">
          </OvenPlayer>
        </b-modal>

        <div class="top">
          <div class="locator">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20H5C4.45 20 4 19.55 4 19V7C4 6.45 3.55 6 3 6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 14.5V5.5L17.47 9.6C17.74 9.8 17.74 10.2 17.47 10.4L12 14.5Z" />
            </svg>
            VIDEOS
          </div>
          <b-button class="add" @click="activateModal(null)" type="is-info" size="is-small" icon-left="plus">ADD NEW
            VIDEO</b-button>
        </div>
        <div class="table" v-if="videos.length > 0">
          <div class="headings">
            <div class="heading">
              <h3>Video Name</h3>
            </div>
            <div class="heading">
              <h3>Status</h3>
            </div>
            <div class="heading">
              <h3>Origin</h3>
            </div>
            <div class="heading">
              <h3>Video Time</h3>
            </div>
            <div class="heading">
              <h3>Progress</h3>
            </div>
            <div class="heading">
              <h3>Action</h3>
            </div>
          </div>
          <div class="record" v-for="video in videos" :key="video.id">
            <div class="data play" @click="playVideo(video)">
              <div class="icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5Z"
                    fill="#323232" />
                </svg>
              </div>
              <h3>{{ video.title }}</h3>
            </div>
            <div class="data">
              <h3>
                <span v-if="video.status == 'cdn_upload_completed'" class="circle"></span>
                <span v-else class="circle queued"></span>
                {{ video.status }}
              </h3>
            </div>
            <div class="data">
              <h3>{{ video.origin }}</h3>
            </div>
            <div class="data">
              <h3>{{ secondsToTime(video.videoTime) }}</h3>
            </div>
            <div class="data">
              <h3>{{ video.progress }}%</h3>
            </div>
            <div class="data action">
              <b-dropdown aria-role="list">
                <template #trigger="{ active }">
                  <b-button :icon-right="active ? 'menu-up' : 'menu-down'" />
                </template>
                <b-dropdown-item aria-role="listitem" @click="activateModal(video, 'delete')">Delete</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="resumeCdnUpload(video)">Resume CDN Upload</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="nodata" v-else>No videos available</div>
      </div>
    </div>
  </div>
</template>
<script>
import videoModal from './components/videoModal.vue';
import { API_BASE_URL } from "@/store/variables.js"
export default {
  name: "videos",
  components: { videoModal },
  data() {
    return {
      activate: false,
      type: '',
      form: {
        name: '',
        origin: '',
        status: ''
      },
      videos: [],
      showPlayer: false,
      hlsUrl: ''
    }
  },
  mounted() {
    let vm = this;
    vm.getAllVideos();
    const evtSource = new EventSource(`${API_BASE_URL}/videos/progress`);
    evtSource.onmessage = (e) => {
      let newVideo = JSON.parse(e.data);
      vm.videos = vm.videos.map(video => {
        if (video.videoId == newVideo.videoId) {
          return newVideo;
        }
        return video
      })
    };
  },
  methods: {
    resumeCdnUpload(video) {
      let vm = this;
      vm.$store.dispatch('VIDEO_RESUME_CDN_UPLOAD', video)
        .then(response => {
          vm.$buefy.toast.open({
            message: 'CDN upload resumed',
            type: 'is-success'
          });
          console.log(response.data);
        })
        .catch(error => {
          vm.$buefy.toast.open({
            message: 'Error resuming CDN upload',
            type: 'is-danger'
          });
          console.log(error);
        })
    },
    playVideo(video) {
      this.showPlayer = true
      this.hlsUrl = 'https://wecodefy.fra1.cdn.digitaloceanspaces.com/' + video.videoPath
    },
    secondsToTime(secs) {
      // Round down to nearest whole number to avoid floating point numbers
      secs = Math.floor(secs);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(secs / 3600);
      secs %= 3600; // remaining seconds
      const minutes = Math.floor(secs / 60);
      const seconds = secs % 60;

      // Building the time string
      let timeString = "";

      // Add hours if present
      if (hours > 0) {
        timeString += `${hours}:`;
      }

      // Add minutes if present or if hours are present (to maintain format)
      if (minutes > 0 || hours > 0) {
        timeString += `${minutes.toString().padStart(2, '0')}:`;
      }

      // Add seconds (always shown)
      timeString += seconds.toString().padStart(2, '0');

      return timeString;
    },
    getAllVideos() {
      let vm = this;
      vm.$store.dispatch('VIDEO_GET_ALL')
        .then(response => {
          vm.videos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addVideo() {
      console.log('adding video...')
    },
    activateModal(data, type) {
      let vm = this
      data ? vm.form = data : ''
      type ? vm.type = type : ''
      vm.activate = true
    },
    cancel() {
      this.type = ''
      this.activate = false
      this.form = { name: '', origin: '', status: '' }
      this.getAllVideos();
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/index.scss';

.contain {
  padding: 20px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;

    div {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 10px;

      svg {
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }

      &.add {
        padding: 5px 10px;
        border-radius: 5px;
        background: $lightblue;
        color: $white;
        cursor: pointer;

        svg {
          fill: $white;
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .table {
    .headings {
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr 0.8fr;
      margin: 0 0 10px;
      column-gap: 20px;
      padding: 0 10px;

      .heading {
        h3 {
          font-size: 0.9rem;
          font-weight: 500;
          margin: 5px 0;
          color: $black;
        }
      }
    }

    .record {
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr 0.8fr;
      margin: 10px 0;
      padding: 7px 10px;
      border-radius: 7px;
      column-gap: 20px;
      transition: .8s;

      // &:hover{
      //   background: rgb(241, 241, 241);
      // }
      .data {
        h3 {
          font-size: 0.8rem;
          font-weight: 400;
          margin: 5px 0;
          line-height: 1.7rem;
          color: $black;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: flex;
          align-items: center;

          span {
            width: 10px;
            height: 10px;
            display: block;
            margin: 0 7px 0 0;
            background: #0AEE81;
            border-radius: 100%;

            &.queued {
              background: orange;
            }
          }
        }

        &.play,
        &.action {
          display: flex;
          column-gap: 10px;
          align-items: center;

          .icon {
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: relative;

            img {
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }

        &.action {
          font-size: 0.8rem;
          position: relative;

          .actions {
            display: flex;
            column-gap: 10px;
            position: absolute;
            right: 100%;
            opacity: 0;
            transition: .4s;
            pointer-events: none;

            label {
              background: $grey;
              padding: 3px 10px;
              border-radius: 4px;
              cursor: pointer;
            }

            &.active {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
  }
}
</style>