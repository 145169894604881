import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Videos from '@/views/Videos/Videos.vue'
import Streaming from '@/views/Streaming/Streaming.vue'
import Radio from '@/views/Radio/Radio.vue'
import Users from '@/views/Users/Users.vue'
import Settings from '@/views/Settings/Settings.vue'
import Servers from '@/views/Servers/Servers.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dasboard',
    component: Dashboard
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/streaming',
    name: 'Streaming',
    component: Streaming
  },
  {
    path: '/radio',
    name: 'Radio',
    component: Radio
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/servers',
    name: 'Servers',
    component: Servers
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
