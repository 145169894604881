<template>
    <div class="upload-widget-wrapper">
        <b-upload :accept="accept" drag-drop @input="onFileChanged">
            <slot></slot>
        </b-upload>
    </div>
</template>
  
<script>
import * as tus from 'tus-js-client';
import axios from "axios";
import moment from 'moment';
export default {
    name: "videoModal",
    props: {
        videoId: {
            type: String,
            default: null
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        libraryId: {
            type: String,
            default: null
        },
        app: {
            type: String,
            default: "none.com"
        },
        streamServerBaseUrl: {
            type: String,
            default: "https://streaming-server-api.watch.rw"
        },
        accept: {
            type: String,
            default: ".mp4,.mkv,.mp3"
        }

    },
    data() {
        return {
            eventSource: null
        }
    },
    mounted() {
    },
    beforeDestroy() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    },
    methods: {
        $moment: moment,
        getFileExtension(filename) {
            return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        },
        onFileChanged(file) {
            let vm = this;
            if (!file) return;
            const ext = vm.getFileExtension(file.name);
            vm.startUpload(file, ext == 'mp3' ? 'audio' : 'video');
        },
        startUpload(file, fileType) {
            let vm = this;
            const payload = {
                canCreate: vm.canCreate,
                videoId: vm.videoId,
                libraryId: vm.libraryId,
                app: vm.app,
                fileType: fileType,
            };
            axios({
                url: vm.streamServerBaseUrl + "/api/v1/videos/initiate-creation",
                method: "POST",
                data: payload
            }).then(response => {
                vm.$emit('created', {
                    videoId: response.data.videoId,
                    fileType: fileType,
                });
                vm.eventSource = new EventSource(`${vm.streamServerBaseUrl}/api/v1/videos/progress/${response.data.videoId}`);
                vm.eventSource.onmessage = (e) => {
                    let data = JSON.parse(e.data);
                    if (data) {
                        vm.$emit('progress',
                            {
                                type: data.status,
                                percentage: data.progress
                            }
                        );
                        if (data.status == 'uploaded') {
                            vm.$emit('completed', {
                                type: 'cdn_upload',
                                data: data
                            });
                            vm.eventSource.close();
                            vm.eventSource = null;
                        }
                    }
                };

                var upload = new tus.Upload(file, {
                    endpoint: vm.streamServerBaseUrl + "/api/v1/videos/files",
                    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                    headers: {
                        VideoId: response.data.videoId,
                    },
                    metadata: {
                        title: response.data.title,
                        videoId: response.data.videoId,
                        fileType: fileType,
                    },
                    onError: function (error) {
                        vm.$emit('error', error);
                    },
                    onProgress: function (bytesUploaded, bytesTotal) {
                        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                        vm.$emit('progress',
                            {
                                type: 'server_upload',
                                bytesUploaded: bytesUploaded,
                                bytesTotal: bytesTotal,
                                percentage: parseInt(percentage),
                                label: `Uploaded ${vm.humanFileSize(bytesUploaded)} of ${vm.humanFileSize(bytesTotal)} (${percentage}%)`
                            }
                        );
                    },
                    onSuccess: function () {
                        vm.$emit('completed', {
                            type: 'server_upload',
                        });
                    }
                });

                // Check if there are any previous uploads to continue.
                upload.findPreviousUploads().then((previousUploads) => {
                    // Found previous uploads so we select the first one.
                    if (previousUploads.length > 0 && previousUploads[0].metadata.title == response.data.title) {
                        let creationTime = vm.$moment(previousUploads[0].creationTime).fromNow();
                        vm.$buefy.dialog.confirm({
                            title: 'Important Message',
                            message: `You already started uploading this file ${creationTime}. Do you want to resume this upload?`,
                            cancelText: 'NO, START OVER',
                            confirmText: 'YES, RESUME',
                            type: 'is-success',
                            canCancel: ['button'],
                            onConfirm: () => {
                                upload.resumeFromPreviousUpload(previousUploads[0]);
                                upload.start();
                                vm.$emit('started', {
                                    videoId: response.data.videoId,
                                    fileType: fileType,
                                });
                            },
                            onCancel: () => {
                                upload.start();
                                vm.$emit('started', {
                                    videoId: response.data.videoId,
                                    fileType: fileType,
                                });
                            }
                        })
                    } else {
                        upload.start();
                        vm.$emit('started', {
                            videoId: response.data.videoId,
                            fileType: fileType,
                        });
                    }
                });


            }).catch(error => {
                vm.$emit('error', error);
            })

        },
        humanFileSize(bytes, si = true, dp = 1) {
            const thresh = si ? 1000 : 1024;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
            const units = si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10 ** dp;

            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
            return bytes.toFixed(dp) + ' ' + units[u];
        }
    }
}
</script>