<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <div class="chart-analytics">
          <div class="analytic">
            <label>Video in queue</label>
            <div class="count-chart">
              <h3 class="count">20</h3>
              <div class="chart">
                <img src="@/assets/imgs/network.svg" alt="">
              </div>
            </div>
          </div>
          <div class="analytic">
            <label>Active stream</label>
            <div class="count-chart">
              <h3 class="count">5</h3>
              <div class="chart">
                <img src="@/assets/imgs/network.svg" alt="">
              </div>
            </div>
          </div>
          <div class="analytic">
            <label>Incoding</label>
            <div class="count-chart">
              <h3 class="count">5</h3>
              <div class="chart">
                <img src="@/assets/imgs/network.svg" alt="">
              </div>
            </div>
          </div>
          <div class="analytic">
            <label>Failed video</label>
            <div class="count-chart">
              <h3 class="count">5</h3>
              <div class="chart">
                <img src="@/assets/imgs/network.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="system-status">
          <div class="column">
            <label>SYSTEM STATUS</label>
            <div class="status">
              <label>CPU: 90 %</label>
              <b-progress 
                  :rounded="false" 
                  :value="90" 
                  size="is-small"
                  type="is-success" 
                  :show-value="false"
              ></b-progress>
            </div>
            <div class="status">
              <label>RAM: 70 %</label>
              <b-progress 
                  :rounded="false" 
                  :value="70" 
                  size="is-small"
                  type="is-success" 
                  :show-value="false"
              ></b-progress>
            </div>
          </div>
          <div class="column">
            <label>STORAGE: 10GB/200GB</label>
            <b-progress 
                :rounded="false" 
                :value="10" 
                size="is-small"
                type="is-success" 
                :show-value="false"
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name: "dashboard",
  data(){
    return{
      
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../scss/index.scss';
.contain{
  padding: 20px;
  .chart-analytics{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    .analytic{
      font-size: 0.9rem;
      background: $grey;
      padding: 5px 10px;
      border-radius: 5px; 
      label{
        color: $black;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        margin: 7px 0;
      }
      .count-chart{
        padding: 5px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 20% 70%;
        align-items: baseline;
        .count{
          font-size: 2.6rem;
          font-weight: 400;
        }
        .chart{
          width: 100%;
          height: 100px;
          position: relative;
          display: flex;
          img{
            width: 100%;
            height: 100%; 
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }
  .system-status{
    margin: 10px 0;
    font-size: 0.9rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    label{
      font-weight: 500;
      display: block;
      margin: 5px 0;
    }
    input{
      accent-color: $lightblue;
    }
    .status{
      display: grid;
      align-items: center;
      grid-template-columns: 30% 70%;
      label{
        font-weight: 400;
        display: block;
      }
    }
  }
}
</style>