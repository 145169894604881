<template>
    <div class="wecodefy-player-wrapper">
        <div id="wecodefy-player-container" ref="ovenplayer"></div>
    </div>
</template>
  
<script>
import OvenPlayer from 'ovenplayer';

class CustomFragmentLoader extends Hls.DefaultConfig.loader {
    constructor(config) {
        super(config);
        const load = this.load.bind(this);
        this.load = function (context, config, callbacks) {
            // you can replace  urls
            // context.url = context.url.replace('from-A', 'to-B')
            load(context, config, callbacks);
        };
    }
}

export default {
    name: "wecodefy-player",
    props: {
        hlsUrl: {
            type: String,
            default: null
        },
        webRTCUrl: {
            type: String,
            default: null
        },
        videoUrl: {
            type: String,
            default: null
        },
        muted: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        latency: {
            type: String,
            default: 'normal'
        },
    },
    data() {
        return {
            player: null,
            isInitialLoading: true,
            hideLoadingWrapper: false,
        };
    },
    computed: {

    },
    mounted() {
        let vm = this;
        vm.initiatePlayer();
    },
    beforeDestroy() {
        let vm = this;
        if (vm.player) {
            vm.player.remove();
        }
    },
    watch: {
    },
    methods: {
        initiatePlayer() {
            let vm = this;
            OvenPlayer.debug(false);
            const sources = [];
            if (vm.webRTCUrl && vm.latency === 'low') {
                sources.push({
                    label: "Low Latency",
                    type: "webrtc",
                    default: true,
                    file: vm.webRTCUrl
                });
            }
            if (vm.hlsUrl && vm.latency === 'normal') {
                sources.push({
                    label: 'Normal Lantecy',
                    type: 'hls',

                    file: vm.hlsUrl
                });
            }
            if (vm.videoUrl && vm.latency === 'video') {
                sources.push({
                    label: 'Video',
                    type: 'mp4',
                    file: vm.videoUrl
                });
            }
            const options = {
                sources: sources,
                mute: vm.muted,
                title: vm.title,
                loop: vm.loop,
                webrtcConfig: {
                    timeoutMaxRetry: 100,
                    connectionTimeout: 3000
                },
                hlsConfig: {
                    fLoader: CustomFragmentLoader
                },

            }
            vm.player = OvenPlayer.create('wecodefy-player-container', options);
            vm.player.getConfig().systemText.api.error[511].message = 'Waiting for live streaming';
            vm.player.getConfig().systemText.ui.context = vm.title;
            vm.player.getConfig().systemText.ui.controls.live = 'Live';
            vm.player.getConfig().systemText.ui.controls.low_latency_live = 'Live';
            vm.player.getConfig().systemText.ui.controls.low_latency_p2p = 'Live';

            vm.player.once('bufferChanged', (data) => {
                if (data.duration > 0 || vm.webRTCUrl) {
                    vm.isInitialLoading = false;
                    setTimeout(() => {
                        vm.hideLoadingWrapper = true;
                        vm.player.setCurrentQuality(3)
                        vm.$emit('started');
                        vm.player.play();
                    }, 1000);
                }
            });
            vm.player.on('error', (error) => {
                vm.hideLoadingWrapper = false;
                vm.isInitialLoading = true;
                vm.$emit('error', error);
                vm.$emit('ended');
            });
            vm.player.on('hlsPrepared', (hls) => {
            });
            vm.player.on('fullscreenChanged', (fullscreen) => {
                if (fullscreen) {
                    screen.orientation.lock("landscape-primary");
                } else {
                    screen.orientation.lock("portrait-primary");
                }
                vm.$emit('fullscreen', fullscreen);
            });
        }
    },
};
</script>

<style lang="scss">
.op-context-panel {
    display: none !important;
}

.wecodefy-player-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    position: relative;

    .wecodefy-player-loading-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        z-index: 999;
    }

    #wecodefy-player-container {
        height: 100%;
        width: 100%;
        --op-accent-color: var(--wecodefy-secondary-color, #9fc953);
    }
}
</style>