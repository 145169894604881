import Vue from 'vue'
import Vuex from 'vuex'

import User from './modules/user.js';
import Videos from './modules/videos.js';


Vue.use(Vuex)
export default new Vuex.Store({
    state: () => ({
        eventBus: new Vue(),
        access_token: 'no_access_token',
    }),
    mutations: {
        ["SET_TOKEN"]: (state, payload) => {
            state.access_token = payload;
        },
    },
    actions: {

    },
    getters: {
        getUserAccessToken(state) {
            return state.access_token ? state.access_token : "no_access_token";
        },
    },
    modules: {
        User,
        Videos
    }
})