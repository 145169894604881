<template>
  <div class="overlay">
    <div class="deletepop" v-if="type == 'delete'">
      <label>Are you sure you want to delete this stream?</label>
      <div class="btns">
        <b-button type="is-primary">Delete</b-button>
        <b-button type="is-primary is-light" @click="cancel()">Cancel</b-button>
      </div>
    </div>
    <form class="addpop" @submit.prevent="save()" v-else-if="form">
      <div class="top">
        <label class="head" v-if="!form.id">Add stream</label>
        <label class="head" v-else>Edit stream</label>
      </div>
      <label>Name</label>
      <b-input type="text" v-model="form.eventTitle" placeholder="Enter stream title" required></b-input>
      <label>App in use</label>
      <b-input type="text" v-model="form.app" placeholder="Enter origin" required></b-input>
      <label>Stream Path</label>
      <b-input type="text" v-model="form.path" placeholder="Enter stream path" required></b-input>
      <div class="btns">
        <b-button type="is-primary" v-if="form.id">Update Stream</b-button>
        <b-button type="is-primary" v-else>Add stream</b-button>
        <b-button type="is-primary is-light" @click.prevent="cancel()">Cancel</b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "streamModal",
  props: {
    data: { 
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data(){
    return{
      form: null
    }
  },
  methods:{
    currentData(){
      let vm = this
      vm.form = {
        id: vm.data.id ? vm.data.id : null,
        eventTitle: vm.data.eventTitle,
        app: vm.data.app,
        path: vm.data.path
      }
    },
    save(){
      console.log("saving changes......")
    },
    cancel(){
      let vm = this
      vm.$emit('cancel')
    }
  },
  mounted(){
    this.currentData()
  }
}
</script>

<style>

</style>