<template>
  <div class="page">
    <Sidebar />
    <div class="container">
      <Topbar />
      <div class="contain">
        <b-modal v-model="activate">
          <serverModal :data="form" :type="type" @cancel="cancel()" />
        </b-modal>
        <div class="top">
          <div class="locator">
            <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z"/></svg>
            SERVERS
          </div>
          <b-button class="add" @click="activateModal()" type="is-info" size="is-small" icon-left="plus">ADD SERVER</b-button>
        </div>
        <section>
          <b-tabs size="is-small" class="block">
              <b-tab-item label="ORIGIN SERVERS">
                <originServers @activateModal="activateModal" />
              </b-tab-item>
              <b-tab-item label="EDGES SERVERS">
                <edgesServers @activateModal="activateModal" />
              </b-tab-item>
              <b-tab-item label="LOAD BALANCER SERVERS">
                <LoadServers @activateModal="activateModal" />
              </b-tab-item>
            </b-tabs>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import serverModal from './components/serverModal.vue'
import originServers from './components/originServers.vue'
import edgesServers from './components/edgesServers.vue'
import LoadServers from './components/loadServers.vue'
export default{
  name: "servers",
  components: { serverModal, originServers, edgesServers, LoadServers },
  data(){
    return{
      activate: false,
      type: '',
      form: { ip: '', user: '' , password: '', type: ''},
    }
  },
  methods:{
    cancel(){
      this.activate = false
      this.type = ''
      this.form = { ip: '', user: '' , password: '', type: ''}
    },
    activateModal(data,type){
      let vm = this
      data ? vm.form = data : ''
      type ? vm.type = type : ''
      vm.activate = true
    },
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/index.scss';
.contain{
  padding: 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
    div{
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 10px;
      svg{
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }
      &.add{
        padding: 5px 10px;
        border-radius: 5px;
        background: $lightblue;
        color: $white;
        cursor: pointer;
        svg{
          fill: $white;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .properties{
    .property{
      margin-bottom: 10px;
      font-size: 0.9rem;
      font-weight: 600;
      span{
        font-weight: 300;
      }
    }
    .wrap-property{
      display: grid;
      grid-template-columns: 40% 10%;
      column-gap: 30px; 
      margin-bottom: 10px;
      align-items: center;
      .property{
        margin: 0;
      }
    }
  }
  .tabs{
    display: flex;
    column-gap: 20px;
    font-size: 0.8rem;
    margin: 20px 0;
    .tab{
      text-transform: uppercase;
      font-weight: 400;
      padding: 7px 0;
      cursor: pointer;
      &.active{
        border-bottom: 1px solid $darkblue;
      }
    }
  }
  .table{
    .record{
      display: grid;
      grid-template-columns: 0.5fr 2.5fr 1fr 1fr;
      margin: 10px 0;
      padding: 7px 10px;
      border-radius: 7px;
      column-gap: 20px;
      transition: .8s;
      .index{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 0.8rem;
        border: 2px solid $black;
      }
      .descript{
        h3{
          font-size: 0.8rem;
          font-weight: 600;
          margin: 3px 0; 
          color: $black;
        }
      }
      h3{
        font-size: 0.8rem;
        font-weight: 600;
        margin: 3px 0; 
        color: $black;
      }
      .details{
        display: grid;
        grid-template-columns: 1fr;
        h3{
          font-size: 0.7rem;
          font-weight: 500;
          margin: 2px 0; 
          color: $lightblue;
          span{
            color: $black;
            font-weight: 400;
            margin: 0 0 0 5px;  
          }
        }
        .wrap-detail{
          display: flex;
          align-items: center;
          label{
            margin-left: 30px;
            cursor: pointer;
            font-weight: 600;
            font-size: 0.8rem;
          }
        }
      }
      .option{
        display: flex;
        column-gap: 10px;
      }
    } 
  }
}
</style>