<template>
  <div class="sidebar">
    <div class="logo">
      <img src="@/assets/logo.svg" alt="">
    </div>
    <div class="links">
      <router-link to="/dashboard">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_417_635)"><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11V16C9 16.55 8.55 17 8 17ZM12 17C11.45 17 11 16.55 11 16V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V16C13 16.55 12.55 17 12 17ZM16 17C15.45 17 15 16.55 15 16V14C15 13.45 15.45 13 16 13C16.55 13 17 13.45 17 14V16C17 16.55 16.55 17 16 17Z" fill="#323232"/></g><defs><clipPath id="clip0_417_635"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
        DASHBOARD
      </router-link>
      <label>LIBRARY</label>
      <div class="grp-links">
        <router-link to="/videos">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20H5C4.45 20 4 19.55 4 19V7C4 6.45 3.55 6 3 6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 14.5V5.5L17.47 9.6C17.74 9.8 17.74 10.2 17.47 10.4L12 14.5Z" /></svg>
          VIDEOS
        </router-link>
        <router-link to="/streaming">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.11 16.89C6.68 17.32 5.97 17.28 5.6 16.8C4.53 15.39 4 13.69 4 12C4 10.31 4.53 8.62 5.59 7.2C5.96 6.72 6.67 6.67 7.1 7.1C7.45 7.45 7.49 8 7.2 8.39C6.4 9.46 6 10.73 6 12C6 13.27 6.4 14.53 7.2 15.6C7.5 15.99 7.46 16.54 7.11 16.89ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM16.9 16.9C16.55 16.55 16.51 16 16.81 15.61C17.6 14.54 18 13.27 18 12C18 10.73 17.6 9.47 16.8 8.4C16.5 8.01 16.54 7.45 16.89 7.1C17.32 6.67 18.03 6.71 18.4 7.19C19.47 8.6 20 10.29 20 11.99C20 13.68 19.47 15.37 18.41 16.79C18.04 17.28 17.33 17.33 16.9 16.9ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/></svg>
          STREAMING
        </router-link>
        <router-link to="/radio">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.24 6.15C2.51 6.43 2 7.17 2 8V20C2 21.1 2.9 22 4 22H20C21.11 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6H8.3L15.73 3C16.19 2.81 16.41 2.29 16.22 1.83C16.03 1.37 15.51 1.15 15.05 1.34L3.24 6.15ZM7 20C5.34 20 4 18.66 4 17C4 15.34 5.34 14 7 14C8.66 14 10 15.34 10 17C10 18.66 8.66 20 7 20ZM20 12H18V11C18 10.45 17.55 10 17 10C16.45 10 16 10.45 16 11V12H4V9C4 8.45 4.45 8 5 8H19C19.55 8 20 8.45 20 9V12Z" /></svg>
          RADIO
        </router-link>
      </div>
      <label>OTHER</label>
      <div class="grp-links">
        <router-link to="/users">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_201_256)"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H22C22.55 20 23 19.55 23 19V17C23 14.82 19.43 13.53 16.67 13.13Z" /><path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M9 13C6.33 13 1 14.34 1 17V19C1 19.55 1.45 20 2 20H16C16.55 20 17 19.55 17 19V17C17 14.34 11.67 13 9 13Z" /></g><defs><clipPath id="clip0_201_256"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
          USERS
        </router-link>
        <router-link to="/settings">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_201_257)"><path d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99996C9.74996 2 9.53996 2.18 9.50996 2.42L9.12996 5.07C8.51996 5.32 7.95996 5.66 7.43996 6.05L4.94996 5.05C4.71996 4.96 4.45996 5.05 4.33996 5.27L2.33996 8.73C2.20996 8.95 2.26996 9.22 2.45996 9.37L4.56996 11.02C4.52996 11.34 4.49996 11.67 4.49996 12C4.49996 12.33 4.52996 12.66 4.56996 12.98L2.45996 14.63C2.26996 14.78 2.21996 15.05 2.33996 15.27L4.33996 18.73C4.45996 18.95 4.72996 19.03 4.94996 18.95L7.43996 17.95C7.95996 18.35 8.51996 18.68 9.12996 18.93L9.50996 21.58C9.53996 21.82 9.74996 22 9.99996 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.49996 13.93 8.49996 12C8.49996 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z" /></g><defs><clipPath id="clip0_201_257"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
          SETTINGS
        </router-link>
        <router-link to="/servers">
          <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z"/></svg>
          SERVERS
        </router-link>
      </div>
    </div>
    <div class="system-status">
      <label>SYSTEM STATUS</label>
      <div class="status">
        <label>CPU: 10 %</label>
        <b-progress 
          :rounded="false" 
          :value="90" 
          size="is-small"
          type="is-success" 
          :show-value="false"
        ></b-progress>
      </div>
      <div class="status">
        <label>RAM: 70 %</label>
        <b-progress 
          :rounded="false" 
          :value="70" 
          size="is-small"
          type="is-success" 
          :show-value="false"
        ></b-progress>
          </div>
          <label>STORAGE: 10GB/200GB</label>
          <b-progress 
          :rounded="false" 
          size="is-small"
          :value="10" 
          type="is-success" 
          :show-value="false"
          format="percent"
          ></b-progress>
        </div>
      </div>
    </template>

<script>
export default {
  name: "sidebar",
  data(){
    return{
      CPUvalue: 30
    }
  }
}
</script>

<style lang="scss">
@import '../scss/index.scss';
.sidebar{
  width: 300px;
  height: 100vh;
  padding: 10px 30px;
  background: $grey;
  position: relative;
  border-right: 1px solid #7171711b;
  .logo{
    width: 150px;
    height: 70px;
    margin: 0 auto 20px;
    position: relative;
    img{
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .links{
    font-size: 0.9rem;
    label{
      font-weight: 500;
      margin: 15px 0;
    }
    a{
      text-decoration: none;
      outline: none;
      display: block;
      margin: 20px 0;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      column-gap: 20px;
      width: 100%;
      color: $black;
      svg{
        width: 20px;
        height: 20px;
        fill: $black;
        flex-shrink: 0;
      }
      &.router-link-active{
        color: $lightblue;
        svg{
          fill: $lightblue;
        }
      }
    }
    .grp-links{
      a{
        padding: 0 0 0 20px;
      }
    }
  }
  .system-status{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: $grey;
    padding: 10px 20px;
    font-size: 0.9rem;
    label{
      font-weight: 500;
      display: block;
      margin: 5px 0;
    }
    input{
      width: 100%;
      accent-color: $lightblue;
    }
    .status{
      display: grid;
      align-items: center;
      grid-template-columns: 30% 70%;
      label{
        font-weight: 400;
      }
    }
  }
}
</style>